import React, { Component } from 'react'
import { Card, CardHeader, CardBody, CardTitle} from "reactstrap"
import DataTableDeleteAndEdit from 'components/Extensions/Tables/DataTableDeleteAndEdit.js'
import api_url from "../../helpers/config.js"
import { Link }from "react-router-dom"
import { fetchGetData } from 'helpers/api.js'
import { DeviceContext } from 'context/DeviceContext'

class Inconsistent_connections_Card extends Component {
  static contextType = DeviceContext

  state = {
    connections: [],
    connections_with_attributes: [],
    components: []
  }

  getItems(){
    /*fetchGetData(this.context.device, 'inputs_output_sum_with_types_and_attribute_types')
    .then(connections_with_attributes => {
      if (connections_with_attributes.dataExists != 'false') {
        this.setState({ connections_with_attributes })
      }
      })
    .catch(err => console.log(err))*/

    fetchGetData(this.context.device, 'inputs_output_sum')
    .then(connections => {
      if (connections.dataExists != 'false') {
        this.setState({ connections })
      }
      })
    .catch(err => console.log(err)) 
    
    fetchGetData(this.context.device, 'components')
    .then(components => {
      if (components.dataExists != 'false') {
        this.setState({ components })
      }
      })
    .catch(err => console.log(err))
    
  }

  componentDidMount(){
    this.getItems()
  }

  //https://stackoverflow.com/questions/3115982/how-to-check-if-two-arrays-are-equal-with-javascript
  arraysEqual(a, b) {
    if (a === b) return true
    if (a == null || b == null) return false
    if (a.length !== b.length) return false
  
    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.
  
    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false
    }
    return true
  }

  render() {
    var connections_view = {}
    var connections_with_attributes = {}

    if (this.state.connections.length !== 0 && this.state.connections.dataExists !== 'false') {
      connections_view = this.state.connections.filter(item => item.view_id == this.props.view_id)
      connections_with_attributes = this.state.connections_with_attributes.filter(item => item.view_id == this.props.view_id)
      
      
      var inconsistent_connections = []
      connections_view.map(connection => {
        var input_attribute_type_ids = []
        var output_attribute_type_ids = []
        connections_with_attributes.filter(item => item.inputs_output_id == connection.inputs_output_id).map(connection_with_attribute => {
          input_attribute_type_ids.push(connection_with_attribute.input_types_attribute_type_id)
          output_attribute_type_ids.push(connection_with_attribute.output_types_attribute_type_id)
        })
        if(!this.arraysEqual(input_attribute_type_ids.sort(),output_attribute_type_ids.sort())){
          inconsistent_connections.push(connection)
        }
      })

      if (inconsistent_connections.length > 0) {
        inconsistent_connections.map(item => {
          if (this.state.components.length != 0){
            var input_component_name_var = this.state.components.filter(comp => comp.component_id == item.input_component_id)[0].name
            var output_component_name_var = this.state.components.filter(comp => comp.component_id == item.output_component_id)[0].name
            item.input_component_name = input_component_name_var
            item.output_component_name = output_component_name_var
          }
        })

        return (
          <Card>
            <CardHeader>
              <div style={{display : 'inline-block'}}>
                <CardTitle tag="h4">Inkonsistente Verbindungen</CardTitle>
              </div>
            </CardHeader>
            <CardBody>
              <DataTableDeleteAndEdit
                items={inconsistent_connections}
                item_type={'inputs_output'}
                columns={['Input-Name', 'Input-Komponente', 'Output-Name', 'Out-Komponente']}
                columns_DB={['input_name','input_component_name',  'output_name', 'output_component_name']}
              />
            </CardBody>
          </Card>
        )
      }
    }
    return <div></div>
  }
}

export default Inconsistent_connections_Card